import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang(cs)',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home')
      },
      {
        path: 'brand',
        name: 'Brand',
        component: () => import('../views/Brand')
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('../views/About')
      },
      {
        path: 'security',
        name: 'Security',
        component: () => import('../views/Security')
      },
      {
        path: 'dictionary',
        name: 'Dictionary',
        component: () => import('../views/Dictionary')
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: () => import('../views/Contacts')
      },
      {
        path: 'rates',
        name: 'Rates',
        component: () => import('../views/Rates')
      },
      {
        path: 'documents',
        name: 'Documents',
        component: () => import('../views/Documents')
      },
      {
        path: 'documents/gdpr',
        name: 'GDPR',
        component: () => import('../views/documents/Gdpr')
      },
      {
        path: 'documents/terms-and-conditions',
        name: 'Terms & Conditions',
        component: () => import('../views/documents/Tnc')
      },
      {
        path: 'exchange',
        name: 'Exchange',
        component: () => import('../views/Exchange')
      },
      {
        path: 'fees',
        name: 'Fees',
        component: () => import('../views/Fees')
      },
      {
        path: 'partnership',
        name: 'Partnership',
        component: () => import('../views/Partnership')
      },
      {
        path: 'bidaskbit',
        name: 'BidAskBit',
        component: () => import('../views/BidAskBit')
      },
      {
        path: 'wallets',
        name: 'Wallets',
        component: () => import('../views/Wallets')
      },
      {
        path: 'transaction-types',
        name: 'TransactionTypes',
        component: () => import('../views/TransactionTypes')
      },
      {
        path: '/*',
        name: '404',
        meta: {
          layout: 'default'
        },
        component: () => import('../views/404.vue')
      }
    ]
  },
  {
    path: '/*',
    name: '404',
    meta: {
      layout: 'default'
    },
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let firstLoad = true
function defaultLanguage () {
  const supportedLanguages = ['cs']
  const browserLang = window.navigator.language.split('-')[0]
  let defaultLang = 'cs'
  if (supportedLanguages.includes(browserLang)) {
    defaultLang = browserLang
  }
  return defaultLang
}

router.beforeResolve((to, from, next) => {
  if (store.state.isNavToggle) store.state.isNavToggle = false
  const loader = document.getElementById('loader').classList
  if (to.name !== from.name) {
    loader.add('active')
    setTimeout(() => {
      next()
    }, 500)
  } else {
    next()
  }
})
router.afterEach((to, from) => {
  const loader = document.getElementById('loader').classList
  const waiting = firstLoad ? 1250 : 500
  const defLang = defaultLanguage()
  if (firstLoad && defLang !== to.params.lang) {
    router.push({
      params: { lang: defLang }
    })
  }
  firstLoad = false
  setTimeout(() => {
    loader.remove('active')
  }, waiting)
})

export default router
