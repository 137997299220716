<template>
  <div class="cookies">
    <div class="cookies__wrapper">
      <div class="cookies__block">
        <div class="cookies__head">
          <div class="img img--cookies">
            <img src="/img/cookies.svg" alt="">
          </div>
        </div>
        <div class="cookies__body">
          <div class="cookies__visible">
            <p class="text ts-12 lh-175 tc-cadet">
              {{ cc.text }}
            </p>
          </div>
          <div class="cookies__hidden" :class="{'active': isDetails}">
            <div class="cookies__checkbox">
              <input type="checkbox" id="cookies-1" v-model="cookies1" />
              <label for="cookies-1" class="cookies__checkbox-box"></label>
              <label for="cookies-1" class="text ts-12 lh-175 tc-night">Nezbytné cookies</label>
            </div>
            <div class="cookies__checkbox">
              <input type="checkbox" id="cookies-2" v-model="cookies2" />
              <label for="cookies-2" class="cookies__checkbox-box"></label>
              <label for="cookies-2" class="text ts-12 lh-175 tc-night">Analytické a statistické cookies</label>
            </div>
            <div class="cookies__checkbox">
              <input type="checkbox" id="cookies-3" v-model="cookies3" />
              <label for="cookies-3" class="cookies__checkbox-box"></label>
              <label for="cookies-3" class="text ts-12 lh-175 tc-night">Reklamní a marketingové</label>
            </div>
          </div>
          <div class="btn btn-t1 btn-night mt-3 mb-2" @click.prevent="acceptCookiesAll">
            <span>Přijmout <span v-if="isDetails">všechny </span>cookies</span>
          </div>
          <div class="btn btn-t2 btn-night" v-if="!isDetails" @click.prevent="detailsCookies">
              <span>Nastavení souborů cookie</span>
          </div>
          <div class="btn btn-t2 btn-night" v-else @click.prevent="acceptCookiesSettings">
            <span>Uložit nastavení</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    isDetails: false,
    cookies1: true,
    cookies2: true,
    cookies3: true,
    storage: window.localStorage
  }),
  computed: {
    cc () {
      return this.$t('components.cookies')
    }
  },
  methods: {
    acceptCookiesAll () {
      this.$store.state.isCookies = true
      this.cookiesSetup(true)
      this.saveSettings(true)
    },
    acceptCookiesSettings () {
      this.$store.state.isCookies = true
      if (this.cookies1 && this.cookies2 & this.cookies3) this.cookiesSetup(true)
      else this.cookiesSetup(false)
    },
    cookiesSetup (accept) {
      document.querySelector('body').style.overflowY = 'scroll'
      if (!accept) {
        this.saveSettings(false)
        if (!document.__defineGetter__) {
          Object.defineProperty(document, 'cookie', {
            get: function () { return '' },
            set: function () { return true }
          })
        } else {
          document.__defineGetter__('cookie', function () { return '' })
          document.__defineSetter__('cookie', function () {})
        }
      }
    },
    detailsCookies () {
      this.isDetails = !this.isDetails
    },
    saveSettings (val) {
      this.storage.setItem('isCookies', val)
    }
  },
  mounted () {
    document.querySelector('body').style.overflowY = 'hidden'
  }
}
</script>
