<template>
  <div id="app" class="app">
    <comp-header/>
    <router-view/>
    <comp-footer/>
    <comp-cookies v-if="!this.$store.state.isCookies" />
    <comp-sidebar/>
    <comp-complaint v-if="this.$store.state.isModalToggle" />
    <comp-contact v-if="this.$store.state.isContactModalToggle" />
    <wgt-crypto-row/>
    <!--<comp-social />-->
    <!--<comp-scroll/>-->
    <comp-loader/>
  </div>
</template>

<script>
import compLoader from '@/components/Loader'
import compHeader from '@/components/Header'
import compFooter from '@/components/Footer'
import compCookies from '@/components/Cookies'
import compSidebar from '@/components/Sidebar'
import compComplaint from '@/components/Modal/Complaint'
import wgtCryptoRow from '@/components/Widgets/CryptoRow'
import compContact from '@/components/Modal/Contact'
export default {
  components: {
    compLoader, compHeader, compFooter, compCookies, compComplaint, compSidebar, wgtCryptoRow, compContact
  },
  beforeCreate () {
    const cryptoHistoryApi = this.$store.state.cryptoHistoryApi
    const cryptoActualApi = this.$store.state.cryptoActualApi
    const cryptoChangesApi = this.$store.state.cryptoChangesApi
    if (!cryptoHistoryApi) this.$store.commit('getCryptoHistory')
    if (!cryptoActualApi) this.$store.commit('getCryptoActual')
    if (!cryptoChangesApi) this.$store.commit('getCryptoChanges')
    this.$store.commit('setCookies')
  },
  metaInfo () {
    return {
      title: 'MyCoiner'
    }
  }
}
</script>

<style lang="scss">
  @import 'assets/scss/styles.scss';
</style>
